export const apiEnv: any = {
  development: {
    baseUrl: "https://sal-api.testing.techhive.dev",
    mediaUrl: "https://saladmin.smartsway.com:3000",
  },
  production: {
    baseUrl: "https://salapi.smartsway.com",
    mediaUrl: "https://saladmin.smartsway.com:3000",
  },
};

const appEnv = process.env.REACT_APP_ENV || "development";
export let BASE_URL: any = apiEnv[appEnv].baseUrl;
export let baseURL: any = apiEnv[appEnv].baseUrl;
export let MEDIA_URL: any = apiEnv[appEnv].mediaUrl;
export let BASE_MEDIA: any = apiEnv[appEnv].mediaUrl;
